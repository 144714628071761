<template>
  <validation-observer
    ref="bankAccountForm"
  >
    <form @submit.prevent="onSubmit">
      <template v-if="isFormCreate === false && bankAccountForm && bankAccountForm.id">
        <b-form-group
          v-if="bankAccountForm.bankCode === '014' || bankAccountForm.bankCode === 'TRUE'"
          label-cols-sm="4"
          label-cols-lg="3"
          label="ตั้งค่าระบบออโต้"
          class="m-0"
        >
          <b-row>
            <b-col>
              <b-form-radio-group
                id="autoBankingStatus"
                v-model="bankAccountForm.isAutoEnable"
                name="autoBankingStatus"
              >
                <b-form-radio :value="true">
                  {{ $t('utils.status.enable') }}
                </b-form-radio>
                <b-form-radio :value="false">
                  {{ $t('utils.status.disable') }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col v-if="bankAccountForm.bankCode === '014'">
              <VerifyAccountModalForm
                :bank-account-id="bankAccountForm.id"
                :bank-no="bankAccountForm.bankAccountNumber"
                @submit="verifyAccount"
              />
            </b-col>
            <b-col v-else-if="bankAccountForm.bankCode === 'TRUE'">
              <VerifyAccountTrueWalletForm
                :bank-account-detail="bankAccountForm"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group
          v-if="bankAccountForm.bankCode === '014'"
          label-cols-sm="4"
          label-cols-lg="3"
          label="Handy SMS ID"
          label-for="handySmsID"
        >
          <b-form-input
            id="handySmsID"
            v-model="bankAccountForm.smsDeviceId"
          />
        </b-form-group>
      </template>
      <b-form-group
        v-if="bankAccountForm.scbAccount"
        label-cols-sm="4"
        label-cols-lg="3"
        :label="$t('bank_accounts.scb_verify_status')"
      >
        <b-badge
          v-if="bankAccountForm.scbAccount.status === 1"
          variant="success"
        >
          Verified
        </b-badge>
        <b-badge
          v-if="bankAccountForm.scbAccount.status === 0"
          variant="warning"
        >
          Pending
        </b-badge>
      </b-form-group>
      <b-form-group
        v-if="userInfo.roleName === 'OWNER' && isFormCreate"
        label-cols-sm="4"
        label-cols-lg="3"
        :label="$t('bank_accounts.select_master')"
      >
        <b-select v-model="selectedMasterId">
          <b-select-option
            value=""
            disabled
          >
            กรุณาเลือก Master
          </b-select-option>
          <b-select-option
            v-for="item in masters"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </b-select-option>
        </b-select>
      </b-form-group>
      <validation-provider
        v-if="isFormCreate && userInfo.roleName !== 'AGENT'"
        v-slot="{ errors }"
        :name="$t('bank_accounts.select_agent')"
        rules="required"
        label-for="select_agent"
      >
        <b-form-group
          label-for="agent"
          label-cols-sm="4"
          label-cols-lg="3"
          :label="$t('bank_accounts.select_agent')"
        >
          <b-form-select
            id="select_agent"
            v-model="bankAccountForm.agentId"
            :state="errors[0] ? false : null"
          >
            <template #first>
              <b-form-select-option
                value=""
                disabled
              >
                {{ `-- ${$t('fields.click_select')} --` }}
              </b-form-select-option>
            </template>
            <b-form-select-option
              v-for="item in agents"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </validation-provider>

      <template v-if="accountType === 'book_bank'">
        <validation-provider
          v-slot="{ errors }"
          :name="$t('bank_accounts.bank')"
          :rules="accountType === 'book_bank' ? 'required' : null"
        >
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            :label="`${$t('bank_accounts.bank')} ${
              accountType === 'book_bank' ? '*' : ''
            }`"
            label-for="bank_code"
          >
            <b-form-select
              id="bank_code"
              v-model="bankAccountForm.bankCode"
              :state="errors[0] ? false : null"
            >
              <template #first>
                <b-form-select-option
                  value=""
                  disabled
                >
                  {{ `-- ${$t('fields.click_select')} --` }}
                </b-form-select-option>
              </template>
              <b-form-select-option
                v-for="bank in thBanks"
                :key="bank.bank_code"
                :value="bank.bank_code"
              >
                {{ bank.bank_name_th }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="account_no"
          rules="required|min:10|regex:^([0-9]+)$"
        >
          <b-form-group
            :label="`${$t('bank_accounts.account_no')} ${
              accountType === 'book_bank' ? '*' : ''
            }`"
            label-for="bank_number"
            label-cols-sm="4"
            label-cols-lg="3"
          >
            <b-form-input
              id="bank_number"
              v-model="bankAccountForm.bankAccountNumber"
              v-mask="'############'"
              :state="errors[0] ? false : null"
              :aria-invalid="false"
              aria-describedby="input-live-feedback"
            />
            <b-form-invalid-feedback id="input-live-feedback">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          :name="$t('bank_accounts.account_name')"
          rules="required"
        >
          <b-form-group
            label-cols-sm="4"
            label-cols-lg="3"
            :label="`${$t('bank_accounts.account_name')} ${'*'}`"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="bankAccountForm.bankAccountName"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
      </template>

      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        :label="'ประเภท'"
      >
        <b-form-radio-group
          id="type"
          v-model="bankAccountForm.bankUseType"
          name="type"
          button-variant="outline-primary"
          size="sm"
          buttons
        >
          <b-form-radio :value="0">
            {{ $t('bank_accounts.types.deposit_withdraw') }}
          </b-form-radio>
          <b-form-radio :value="1">
            {{ $t('bank_accounts.types.deposit') }}
          </b-form-radio>
          <b-form-radio :value="2">
            {{ $t('bank_accounts.types.withdraw') }}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        label="สำหรับยอดฝากตั้งแต่"
        label-for="limit_all_deposit"
      >
        <b-input-group append="บาท">
          <b-input
            id="limit_all_deposit"
            v-model.number="bankAccountForm.minTotalDeposit"
            type="number"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        :label="$t('fields.status')"
        class="m-0"
      >
        <b-form-radio-group
          id="status"
          v-model="bankAccountForm.isEnable"
          name="status"
        >
          <b-form-radio :value="true">
            {{ $t('utils.status.enable') }}
          </b-form-radio>
          <b-form-radio :value="false">
            {{ $t('utils.status.disable') }}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-form-group
        label-cols-sm="4"
        label-cols-lg="3"
        label-for="isRequiredOtp"
      >
        <b-input-group>
          <b-form-checkbox
            v-model="bankAccountForm.isRequiredOtp"
            name="isRequiredOtp"
          >
            <span class="ml-1">
              ต้องการ OTP ถอน
            </span>
          </b-form-checkbox>
        </b-input-group>
      </b-form-group>
      <div class="text-right">
        <b-button
          variant="light"
          class="mr-2"
          @click="onCancel"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-overlay
          :show="isLoading"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            block
          >
            {{ $t('buttons.save') }}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { required } from '@validations'
import { banks } from '@/libs/constants.js';
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  components: {
    ...mapGetters(['isAgent']),
    VerifyAccountModalForm: () => import('./VerifyAccountForm'),
    VerifyAccountTrueWalletForm: () => import('./VerifyAccountTrueForm.vue'),
  },
  props: {
    isFormCreate: {
      type: Boolean,
      default: () => true,
    },
    value: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,

      thBanks: banks,
      selectedMasterId: null,
      selectedAgentId: null,
      bankAccountForm: null,
      accountType: 'book_bank',
      isRequiredOtp: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    masters() {
      return this.userInfo.masters || []
    },
    agents() {
      if (this.userInfo.roleName === 'OWNER') {
        return (
          this.userInfo.masters.find(
            (master) => master.id === this.selectedMasterId
          )?.children || []
        )
      } else if (this.userInfo.roleName === 'MASTER') {
        return this.userInfo.masters[0].children || []
      } else if (this.userInfo.roleName === 'AGENT') {
        return this.userInfo.masters[0].children || []
      }
      return []
    },
    isDisabledSelectAgent() {
      if (this.userInfo.roleName === 'OWNER' && !this.selectedMasterId) {
        return true
      }
      return false
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.setData()
      }
    },
  },
  async created() {
    this.setData()
  },
  methods: {
    ...mapActions(['clearBankAccountForm']),
    setData() {
      if (this.value && this.value.id) {
        const data = JSON.parse(JSON.stringify(this.value))
        this.bankAccountForm = {
          ...data,
          bankUseType: +data.bankUseType,
          minTotalDeposit: +data.minTotalDeposit,
          agentId: data?.agent?.id || null,
        }
      } else {
        this.bankAccountForm = {
          bankCode: '',
          bankAccountNumber: '',
          bankAccountName: '',
          minTotalDeposit: 0,
          limit: 0,
          isEnable: false,
          isAutoEnable: false,
          bankUseType: 0,
          agentId: null,
          smsDeviceId: '',
        }
      }
    },
    onSubmit() {
      this.$refs.bankAccountForm.validate().then( async (success) => {
        if (success) {
          this.$emit('submit', this.bankAccountForm)
        }
      })
    },
    onCancel() {
      this.clearBankAccountForm()
      this.$emit('close')
    },
    verifyAccount() {},
  },
}
</script>
